// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//export const environment = {
//	production: false,
//	apiBaseUrl: 'http://112.196.1.221:1507',
//	siteUrl : 'http://112.196.1.221'
//};
export const environment = {
	production: true,
	apiBaseUrl: 'https://liked-you-api2.signitydemo.in',
	siteUrl : 'https://liked-you.signitydemo.in'
};

// export const environment = {
// 	production: false,
// 	apiBaseUrl: 'http://localhost:1507'
// };
